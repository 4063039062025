import log, { Logger, LogLevelDesc } from 'loglevel';

let loggersInitialised = false;
let initCallbacks: (() => void)[] = [];

const logMethods = [
  'trace',
  'debug',
  'info',
  'warn',
  'error',
];

// location exists on both scopes; one is a Location and the other a WorkerLocation. Both include the search param
const urlParams: URLSearchParams = new URLSearchParams(location.search);
let _window: Window | undefined;

export function initialiseLogger(devMode: boolean): void {
  if (loggersInitialised) {
    return;
  }
  // This runs inside the shared worker, which is in the global worker scope and doesn't have window defined.
  if (typeof window !== 'undefined') {
    _window = window;
  }
  let devLogging: boolean = process.env && process.env.VUE_APP_LOGGING_ENABLED === 'true';
  if (_window?.enableLogging) {
    devLogging = true;
    log.setLevel(log.levels.TRACE, false);
    log.debug('Forcing logging on from window settings');
  }
  if (devMode) {
    devLogging = true;
    log.setLevel(log.levels.TRACE, false);
    log.debug(`*** Running in dev mode. Logging: ${devLogging} ***`);
  }
  const forceLogging: string = urlParams.get('forceLogging') || 'true';
  if (forceLogging === 'true') {
    devLogging = true;
    log.setLevel(log.levels.TRACE, false);
    log.debug('Forcing logging on');
  } else if (forceLogging === 'false') {
    devLogging = false;
    log.debug('Forcing logging off from query parameter');
  }
  if (!devLogging) {
    log.setLevel(log.levels.SILENT, false);
  }

  loggersInitialised = true;
  for (const callback of initCallbacks) {
    callback();
  }
  initCallbacks = [];
}

export function onLogInit(callback: () => void): void {
  if (loggersInitialised) {
    callback();
  } else {
    initCallbacks.push(callback);
  }
}

function isLoggingEnabled(name: string): boolean {
  const loggers: string | null = urlParams.get('enableLogging')?.replace(/%2C/g, ',') ?? '';
  if (loggers) {
    const levels: string[] = loggers.split(',');
    return levels.includes(name);
  }
  return false;
}

function isLoggingDisabled(name: string): boolean {
  const loggers: string | null = urlParams.get('disableLogging')?.replace(/%2C/g, ',') ?? '';
  if (loggers) {
    const levels: string[] = loggers.split(',');
    return levels.includes(name);
  }
  return false;
}

export function getPrefixedLogger(name: string, colour?: string, defaultLevel?: LogLevelDesc): Logger {
  const logger = log.getLogger(name);
  logger.setLevel(defaultLevel ?? log.getLevel(), false);
  if (isLoggingEnabled(name)) {
    logger.setLevel(log.levels.TRACE, false);
  }
  if (isLoggingDisabled(name)) {
    logger.setLevel(log.levels.SILENT, false);
  }

  const loggerUncast: Record<string, unknown> = logger as unknown as Record<string, unknown>;
  const methodNames: string[] = Object.getOwnPropertyNames(logger)
    .filter(item => logMethods.includes(item) && ((typeof loggerUncast[item]) === 'function'));
  for (const methodName of methodNames) {
    const method = loggerUncast[methodName] as (...args: unknown[]) => unknown;
    if (colour) {
      loggerUncast[methodName] = method.bind(method, `%c[${name}] ${methodName.toUpperCase()}:`, `color: ${colour}`);
    } else {
      loggerUncast[methodName] = method.bind(method, `[${name}] ${methodName.toUpperCase()}:`);
    }
  }
  return logger;
}
